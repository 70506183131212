import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, Checkbox, Layout, TextField, FormLayout, Form, Page, Card, Banner, Button, Tooltip, Box, BlockStack } from '@shopify/polaris';
import { LabelPrinterIcon, TransactionIcon, XCircleIcon } from '@shopify/polaris-icons';
import { useUrl, handleSaveWithSecondPayload } from '../../shared/util/hanldesave.js';
import { useLookupStore } from '../../context/useStore.js';
import CompanySelect from '../../shared/components/companySelect.jsx';
import { getCurrenctDatetimeYYYMMDD, getRequest, alphabetize } from '../../shared/components/functions.jsx';
import DetailFileBrowser from '../../shared/components/files/DetailFileBrowser.jsx';
import { validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import InventoryCard from './components/InventoryCard.jsx';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { TabTitle } from '../../shell/helmet.jsx';
import { getSchema } from '../../shared/formSchemas.js';
import { useToggle } from '../../shared/useToogle.js';
import EventTimeLine from '../../shared/components/events/eventTimeLine.jsx';
import { CardHeading } from '../../shared/components/cards/CardHeading.jsx';
import { DoublesModal } from './components/DoublesModal.jsx';
import { LineChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip as TooltipRecharts, Legend } from 'recharts';

/*
  FIXME:  
    - ProductName is often not set in access. How should this be handled?
*/

export default function ProductPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let url = useUrl();
  const { units, producttypes, machinebrands, machinetypes } = useLookupStore();
  const [initialProduct, setInitialProduct] = useState('');
  const [product, setProduct] = useState('');
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [company, setCompany] = useState('');
  //added state for new component
  const [inventories, setInventories] = useState([]);
  const [initialInventories, setInitialInventories] = useState([]);
  const [inventoriesDirty, setInventoriesDirty] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [doubles, setDoubles] = useState([]);
  const [doublesModalOpen, setDoublesModalOpen] = useState(false);
  const [matchingPrices, setMatchingPrices] = useToggle(true);
  const [rate, setRate] = useState('1.54');
  const pageTitle = id === 'new' ? 'New product' : product.ProductNameNL;
  const productSchema = getSchema('product', url);

  const [invoicedHistory, setInvoicedHistory] = useState([]);
  const [groupInvoicedHistoryByYear, setGroupInvoicedHistoryByYear] = useState(false);

  const handleRateChange = (e) => {
    const r = parseFloat(e);
    setRate(e);
    setProduct(
      (product) => ({
        ...product,
        ['Eenheids verkoopprijs']: (Math.round(parseFloat(product['Eenheidsaankoopprijs']) * r * 100) / 100).toString(),
        ['Datum  bijw_ VP']: getCurrenctDatetimeYYYMMDD() //eslint-disable-line
      }),
      setIsDirty(true)
    );
  };

  const handleChange = (value, ID) => {
    /*
        if price is changed then the bijw_AP and bijw_VP need to be set to the current date
        this is not handled directly in the database
    */
    if (ID === 'Kg') value = value.replace(',', '.');

    if (ID === 'Eenheidsaankoopprijs') {
      if (matchingPrices) {
        setProduct(
          (product) => ({
            ...product,
            Eenheidsaankoopprijs: value,
            ['Eenheids verkoopprijs']: (Math.round(parseFloat(value) * parseFloat(rate) * 100) / 100).toString(), //eslint-disable-line
            ['Datum  bijw_ VP']: getCurrenctDatetimeYYYMMDD(), //eslint-disable-line
            ['Datum bijw_AP']: getCurrenctDatetimeYYYMMDD() //eslint-disable-line
          }),
          setIsDirty(true)
        );
      } else {
        setProduct((product) => ({
          ...product,
          Eenheidsaankoopprijs: value,
          ['Datum bijw_AP']: getCurrenctDatetimeYYYMMDD() //eslint-disable-line
        }));
        let percent = parseFloat(product['Eenheids verkoopprijs']) / parseFloat(value);
        setRate(Math.round(percent * 100) / 100);
        setIsDirty(true);
      }
      return;
    }

    if (ID === 'Eenheids verkoopprijs') {
      if (matchingPrices) {
        setProduct(
          (product) => ({
            ...product,
            Eenheidsaankoopprijs: (Math.round((parseFloat(value) / parseFloat(rate)) * 100) / 100).toString(),
            ['Eenheids verkoopprijs']: value,
            ['Datum  bijw_ VP']: getCurrenctDatetimeYYYMMDD(), //eslint-disable-line
            ['Datum bijw_AP']: getCurrenctDatetimeYYYMMDD() //eslint-disable-line
          }),
          setIsDirty(true)
        );
      } else {
        setProduct(
          (product) => ({
            ...product,
            ['Eenheids verkoopprijs']: value,
            ['Datum  bijw_ VP']: getCurrenctDatetimeYYYMMDD() //eslint-disable-line
          }),
          setIsDirty(true)
        );
        let percent = parseFloat(value) / parseFloat(product['Eenheidsaankoopprijs']) || 0;
        setRate(Math.round((percent * 100) / 100).toString());
      }
      return;
    }

    /*
      Auto update product name languages
    */
    if (ID === 'ProductNameNL' && id === 'new') {
      setProduct((product) => ({ ...product, ProductNameFR: value, ProductNameDE: value, ProductNameUK: value }), setIsDirty(true));
    }

    if (ID === 'OnderdelenTypeID') {
      setProduct((product) => ({ ...product, OnderdelenTypeID: value }), setIsDirty(true));
    }

    if (ID === 'MachineMerkId') {
      setProduct((product) => ({ ...product, MachineMerkId: value }), setIsDirty(true));
    }

    if (ID === 'MachineTypeId') {
      setProduct((product) => ({ ...product, MachineTypeId: value }), setIsDirty(true));
    }

    setProduct((product) => ({ ...product, [ID]: value }), setIsDirty(true));
  };

  const handleBlur = (e, ID) => {
    const value = e.target.value;
    if (value) setProduct((product) => ({ ...product, [ID]: Math.round(Number(value) * 100) / 100 }));
  };

  const handleDiscard = () => {
    setProduct(initialProduct);
    setInventories(initialInventories);
    setIsDirty(false);
    setInventoriesDirty(false);
    setFormSubmitting(false);
  };

  const handleFormSubmit = async () => {
    setFormSubmitting(true);

    product.Eenheidsaankoopprijs = parseFloat(product.Eenheidsaankoopprijs) || 0;
    product['Eenheids verkoopprijs'] = parseFloat(product['Eenheids verkoopprijs']) || 0;

    if (product.Eenheidsaankoopprijs !== initialProduct.Eenheidsaankoopprijs) {
      product.Eenheidsaankoopprijs = Math.round(product.Eenheidsaankoopprijs * 100) / 100;
    }
    if (product['Eenheids verkoopprijs'] !== initialProduct['Eenheids verkoopprijs']) {
      product['Eenheids verkoopprijs'] = Math.round(product['Eenheids verkoopprijs'] * 100) / 100;
    }

    if (!product.EenheidAantal) product.EenheidAantal = 0;

    setProduct(product);

    if (id !== 'new' && !inventories.find((i) => i.Stockplaats === 1)) {
      alert('Attention: the inventory line for Kast is missing. Please add it to the inventory (even if quantity is 0).');
      return setFormSubmitting(false);
    }

    const errorInSchema = await validateSchema(productSchema, productSchema.cast(product));
    if (!errorInSchema) {
      if (!product.Productref) {
        handleSave(product);
        return setFormSubmitting(false);
      }

      //check for doubles
      const levels = await getRequest(`/api/products/doubles?product=${id}&ref=${product.Productref}&supplier=${product.SupplierID}`);
      if (levels.lvlOne.length > 0 || levels.lvlTwo.length > 0 || levels.lvlThree.length > 0) {
        setDoubles(levels);
        setDoublesModalOpen(true);
        return setFormSubmitting(false);
      } else {
        handleSave(product);
      }
    } else {
      console.log('Product:', product);
      alert(errorInSchema);
    }
    setFormSubmitting(false);
  };

  const handleSave = async (updatedProduct) => {
    let productData = updatedProduct || product;
    await handleSaveWithSecondPayload(
      id,
      'products',
      productData.ProductID,
      'ProductID',
      productSchema.cast(productData),
      inventories,
      setProduct,
      setInitialProduct,
      `inventory/dump/lines/${id}`,
      setInventories,
      setInitialInventories,
      isDirty,
      setIsDirty,
      inventoriesDirty,
      setInventoriesDirty,
      setToast,
      setErrorToast,
      navigate
    );
  };

  useEffect(() => {
    async function fetchData() {
      if (id === 'new') {
        setProduct(productSchema.default());
        setInitialProduct(productSchema.default());
      } else {
        const data = await getRequest(`/api/products/${id}`);
        if (!data) {
          setProduct(productSchema.default());
          setInitialProduct(productSchema.default());
          return;
        }
        const product = data;
        product['Datum bijw_AP'] = product['Datum bijw_AP'] !== null ? String(product['Datum bijw_AP']).substring(0, 22).replace('T', ' ') : null;
        product['Datum  bijw_ VP'] = product['Datum  bijw_ VP'] !== null ? String(product['Datum  bijw_ VP']).substring(0, 22).replace('T', ' ') : null;
        if (product.Eenheidsaankoopprijs !== 0) {
          let percent = product['Eenheids verkoopprijs'] / product.Eenheidsaankoopprijs;
          setRate(Math.round(percent * 100) / 100);
        }
        setProduct(data);
        setInitialProduct(data);

        const invoicedHistory = await getRequest(`/api/metrics/products_invoiced/${id}?groupBy=${groupInvoicedHistoryByYear ? 'YEAR' : 'MONTH'}`);
        setInvoicedHistory(invoicedHistory);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, groupInvoicedHistoryByYear]);

  useEffect(() => {
    if (id !== 'new') getInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      setCompany(await getRequest(`/api/companies/${product.SupplierID}`));
    }
    if (product.SupplierID) fetchData();
  }, [product.SupplierID]);

  const getInventory = async () => {
    const data = await getRequest(`/api/inventory/${id}`);
    //new comp
    setInitialInventories(data);
    setInventories(data);
  };

  const printLabel = async () => {
    const inventory = inventories.find((i) => i.Stockplaats === 1);
    const payload = {
      id: id,
      name: product.ProductNameNL,
      ref: product.Productref || '',
      supplier: company.NAAM,
      rack: inventory?.Rack || '',
      legplank: inventory?.Legplank || ''
    };
    const response = await fetch('/api/products/label/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = `product_${id}.pdf`;
      a.click();
      URL.revokeObjectURL(url);
    } else {
      console.error('Error:', response.status, response.statusText);
    }
  };

  return (
    <Page
      title={pageTitle}
      subtitle={id === 'new' ? '' : '#' + id}
      fullWidth
      primaryAction={id !== 'new' ? { content: 'New product', url: '/products/new' } : null}
      secondaryActions={id !== 'new' ? <Button icon={LabelPrinterIcon} onClick={printLabel} /> : []}
    >
      <TabTitle title={pageTitle} />
      <TheSaveBar isDirty={isDirty || inventoriesDirty} handleSave={handleFormSubmit} handleDiscard={handleDiscard} savingResult={formSubmitting} />
      {product.Discontinued && (
        <Box paddingBlockEnd="400">
          <Banner tone="critical" title="This product is discontinued!" />
        </Box>
      )}
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} toastContent="Product saved" />
      <DoublesModal modalOpen={doublesModalOpen} setModalOpen={setDoublesModalOpen} doubles={doubles} handleDiscard={handleDiscard} handleSave={handleSave} />
      <Layout>
        <Layout.Section>
          <BlockStack gap="400">
            <Card>
              <Form onSubmit={handleFormSubmit}>
                <FormLayout>
                  <CompanySelect isClearable={false} value={product.SupplierID} id="SupplierID" onChange={handleChange} label="Supplier" isDisabled={formSubmitting} />
                  <FormLayout.Group>
                    <TextField
                      id="Productref"
                      maxLength={50}
                      showCharacterCount
                      autoComplete="off"
                      label="Reference"
                      value={product.Productref || ''}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                    <TextField
                      id="PreviousReference"
                      maxLength={1000}
                      showCharacterCount
                      autoComplete="off"
                      label="Previous Reference"
                      value={product.PreviousReference || ''}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                  </FormLayout.Group>
                  <TextField
                    id="ProductNameNL"
                    maxLength={60}
                    showCharacterCount
                    autoComplete="off"
                    label="Product name (dutch)"
                    value={product.ProductNameNL || ''}
                    onChange={handleChange}
                    disabled={formSubmitting}
                  />
                  <TextField
                    id="ProductNameFR"
                    maxLength={60}
                    showCharacterCount
                    autoComplete="off"
                    label="Product name (french)"
                    value={product.ProductNameFR || ''}
                    onChange={handleChange}
                    disabled={formSubmitting}
                  />
                  <TextField
                    id="ProductNameDE"
                    maxLength={60}
                    showCharacterCount
                    autoComplete="off"
                    label="Product name (german)"
                    value={product.ProductNameDE || ''}
                    onChange={handleChange}
                    disabled={formSubmitting}
                  />
                  <TextField
                    id="ProductNameUK"
                    maxLength={60}
                    showCharacterCount
                    autoComplete="off"
                    label="Product name (english)"
                    value={product.ProductNameUK || ''}
                    onChange={handleChange}
                    disabled={formSubmitting}
                  />
                  <FormLayout.Group>
                    <Select
                      id="EenheidID"
                      label="Unit of measure"
                      value={parseInt(product.EenheidID) || ''}
                      options={units
                        .sort((a, b) => alphabetize(a, b, 'Eenheid'))
                        .map((unit) => {
                          return { label: unit.Eenheid, value: unit.EenheidID };
                        })}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                    <TextField id="EenheidAantal" label="Qty per unit" autoComplete="off" type="number" value={String(product.EenheidAantal)} onChange={handleChange} disabled={formSubmitting} />
                  </FormLayout.Group>
                  <TextField
                    id="ExternalQrUrl"
                    maxLength={1000}
                    showCharacterCount
                    autoComplete="off"
                    label="External QR code URL for printed labels"
                    value={product.ExternalQrUrl || ''}
                    onChange={handleChange}
                    disabled={formSubmitting}
                  />
                </FormLayout>
              </Form>
            </Card>
            <Card>
              <CardHeading title="Pricing" />
              <Form onSubmit={handleFormSubmit}>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      id="Eenheidsaankoopprijs"
                      type="number"
                      label="Purchase price"
                      helpText={`Last updated: ${String(product['Datum bijw_AP']).substring(0, 10)}`}
                      prefix="€"
                      value={product.Eenheidsaankoopprijs}
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e, 'Eenheidsaankoopprijs')}
                      disabled={formSubmitting}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {matchingPrices ? <TextField id="rate" label="Rate" type="number" value={rate} onChange={handleRateChange} disabled={formSubmitting} /> : null}
                      <div style={{ padding: '24px' }}>
                        <Tooltip
                          content={matchingPrices ? 'Disable price link for given ratio between purchase and selling price' : 'Enable price link between purchase and selling price for a given ratio'}
                        >
                          <Button size="large" disabled={formSubmitting} onClick={() => setMatchingPrices()} icon={matchingPrices ? XCircleIcon : TransactionIcon} />
                        </Tooltip>
                      </div>
                    </div>
                    <TextField
                      id="Eenheids verkoopprijs"
                      type="number"
                      label="Selling price"
                      helpText={`Last updated: ${String(product['Datum  bijw_ VP']).substring(0, 10)}`}
                      prefix="€"
                      value={product['Eenheids verkoopprijs']}
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e, 'Eenheids verkoopprijs')}
                      disabled={formSubmitting}
                    />
                    <TextField
                      id="ServiceFee"
                      type="number"
                      step="0.01"
                      label="Service Fee"
                      prefix="€"
                      value={product.ServiceFee || ''}
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e, 'ServiceFee')}
                      disabled={formSubmitting}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card>
            <Card>
              <CardHeading title="Shipping and export" />
              <Form onSubmit={handleFormSubmit}>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField id="Kg" autoComplete="off" label="Weight" prefix="kg" type="number" value={product.Kg || ''} onChange={handleChange} disabled={formSubmitting} />
                    <TextField id="HSCode" autoComplete="off" label="HS code" type="text" value={product.HSCode || ''} onChange={handleChange} disabled={formSubmitting} />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card>
            <InventoryCard
              handleFormSubmit={handleFormSubmit}
              id={id}
              product={product}
              inventories={inventories}
              setInventories={setInventories}
              setInventoriesDirty={setInventoriesDirty}
              errorToast={errorToast}
              sectioned
            />
            <Card>
              <CardHeading title="Invoiced & sold quantities per month" />
              {invoicedHistory.length > 0 ? (
                <div style={{ height: '700px' }}>
                  <Checkbox checked={groupInvoicedHistoryByYear} onChange={setGroupInvoicedHistoryByYear} label="Group by year" />
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={invoicedHistory}>
                      <Legend />
                      <CartesianGrid strokeDasharray="1 5" />
                      <XAxis dataKey="period" />
                      <YAxis name="QTY" />
                      <TooltipRecharts />
                      <Line type="monotone" dataKey="invoicedAmount" stroke="#64BE7B" activeDot={{ r: 9 }} />
                      <Line type="monotone" dataKey="salesAmount" stroke="orange" activeDot={{ r: 9 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <p>No invoice/sales data available for this product</p>
              )}
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Product group" />
              <Select
                isClearable
                id="OnderdelenTypeID"
                placeholder="Select product group"
                value={product.OnderdelenTypeID ? String(product.OnderdelenTypeID) : undefined}
                options={producttypes.map((type) => ({ label: type.OnderdelenType, value: String(type.OnderdelenTypeID) }))}
                onChange={handleChange}
                disabled={formSubmitting}
              />
              {/* button to remove product group */}
              <Button
                onClick={() => {
                  setProduct((product) => ({ ...product, OnderdelenTypeID: null }));
                  setIsDirty(true);
                }}
                disabled={formSubmitting}
              >
                Remove
              </Button>
              <Form onSubmit={handleFormSubmit}>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      id="MachineMerkId"
                      placeholder="Select machine brand"
                      label="Machine Brand"
                      value={product.MachineMerkId ? String(product.MachineMerkId) : undefined}
                      options={machinebrands.map((brand) => ({
                        label: brand.merk,
                        value: String(brand.counter)
                      }))}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                    <Select
                      placeholder="Select machine type"
                      id="MachineTypeId"
                      label="Machine Type"
                      value={product.MachineTypeId ? String(product.MachineTypeId) : undefined}
                      options={machinetypes.map((type) => ({
                        label: type.Type,
                        value: String(type.ID)
                      }))}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card>
            <Card>
              <CardHeading title="Status" />
              <Form onSubmit={handleFormSubmit}>
                <FormLayout>
                  <Checkbox id="Discontinued" label="Product discontinued" checked={product.Discontinued} onChange={handleChange} disabled={formSubmitting} />
                </FormLayout>
              </Form>
            </Card>
            <Card id="Note">
              <CardHeading title="Note" />
              <Form onSubmit={handleFormSubmit}>
                <FormLayout>
                  <TextField
                    id="Omschrijving"
                    autoComplete="off"
                    value={product.Omschrijving || ''}
                    onChange={handleChange}
                    multiline={15}
                    placeholder="Edit the product information ✍️"
                    disabled={formSubmitting}
                  />
                </FormLayout>
              </Form>
            </Card>
            {id !== 'new' ? (
              <DetailFileBrowser docTypeId="25" resourceId={id} Klantnummer={company.Klantnummer} docNumber={product.ProductID} FIRNR={company.FIRNR} docType="part" heightPxs="500px" />
            ) : (
              <Card>
                <CardHeading title="Document upload" />
                Upload is available after saving.
              </Card>
            )}
            <EventTimeLine resourceName="PRODUCT" resourceId={id} />
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
