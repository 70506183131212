import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Filters, DataTable, Page, Card, Link } from '@shopify/polaris';
import { useUrl } from '../../shared/util/hanldesave';
import { getTableFooter, alphabetize, fetchTableData } from '../../shared/components/functions.jsx';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import { useLookupStore } from '../../context/useStore.js';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';

export default function MachinesPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const machinebrandsOptions = useLookupStore((state) => state.machinebrands)
    .sort((a, b) => alphabetize(a, b, 'merk'))
    .map((brand) => ({ value: brand.counter, label: brand.merk }));

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    brand: { type: 'multiple', name: 'Brand', value: url.get('brand') || null, choices: machinebrandsOptions },
    type: { type: 'text', name: 'Type', value: url.get('type') || '' },
    serialNumber: { type: 'text', name: 'Serial number', value: url.get('serialNumber') || '' },
    inServiceDateFrom: { type: 'date', name: 'Date in service from', value: url.get('inServiceDateFrom') || '' },
    inServiceDateTo: { type: 'date', name: 'Date in service to', value: url.get('inServiceDateTo') || '' },
    status: { type: 'status', name: 'Service', value: url.get('status') || 'all' },
    zip: { type: 'text', name: 'Zip', value: url.get('zip') || '' }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/machines', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        let out_of_service = item.uitdienst ? String(item.uitdienst).substring(0, 10) : <Badge>Still in service</Badge>;
        let discontinued = item.discontinued ? <Badge tone="critical">Discontinued</Badge> : out_of_service;
        let machine_brand = item.brand ? <Badge tone="info">{item.brand}</Badge> : null;
        return [
          <Link removeUnderline url={'/machines/' + item.counter}>
            {item.counter}
          </Link>,
          <Link removeUnderline url={'/companies/' + item.Firmaid}>
            {item.company_name}
          </Link>,
          item.POSTNR ? item.POSTNR : null,
          item.WOONPL ? item.WOONPL : null,
          machine_brand,
          item.Machinetype,
          item.Serienummer,
          item.indienst ? String(item.indienst).substring(0, 10) : '',
          discontinued
        ];
      })
    : [];

  return (
    <Page title="Machines" fullWidth primaryAction={{ content: 'New machine', url: '/machines/new' }}>
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter machines on number, customer, brand, type and serial number"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
          headings={[<b>Number</b>, <b>Customer</b>, <b>Zip</b>, <b>City</b>, <b>Brand</b>, <b>Type</b>, <b>Serial number</b>, <b>In service</b>, <b>Out of service</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'machines')}
        />
      </Card>
    </Page>
  );
}
